import React, {Component} from "react";
import validator from 'validator/lib/isUUID'

import classes from './AmbientalMap.module.css'

import {LayersControl, Map, Popup, TileLayer} from 'react-leaflet'
import AmbientalMapFilter from '../../../filter/AmbientalMapFilter/AmbientalMapFilter'

import {connect} from 'react-redux'
import * as actions from '../../../../store/actions'
import {toastr} from 'react-redux-toastr'


import GeoJSON from "react-leaflet/es/GeoJSON";
import * as turf from '@turf/turf'


import {
  Button,
  Col,
  Row,
  FormCheckbox,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormTextarea,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  CardImg
} from "shards-react";

import FloatingList from '../FloatingList/FloatingList'
import FloatingLegend from '../../FloatingLegend/FloatingLegend'

import Control from 'react-leaflet-control'
import ZoomControl from "react-leaflet/es/ZoomControl";


//json data
import trajeto from './trajeto'
import manancial from './manancial'
import FeatureGroup from "react-leaflet/es/FeatureGroup";
import MeasureControlDefault from 'react-leaflet-measure';
import {withLeaflet} from 'react-leaflet'
import * as L from 'leaflet';
import '../../MeasureMap/Measure.css'
const deg2dms = require('dms2deg').deg2dms;
const MeasureControl = withLeaflet(MeasureControlDefault);
//import markerIcon from './MarkerIcon/MarkerIcon'

class AmbientalMap extends Component<> {

  state = {
    lng: -53.888200,
    lat: -22.393392,
    zoom: 12,
    bounds: null,
    modalOpen: false,
    mapTileLayers: [{
      key: 'mapbox.light',
      name: 'Black and White - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
  }, {
      key: 'mapbox.satellite',
      name: 'Satélite - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
  }, {
      key: 'mapbox.outdoors',
      name: 'Outdoor - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
  }, {
      key: 'mapbox.dark',
      name: 'Dark - Mapbox',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZG9taW51c3NvbGkiLCJhIjoiY2p2Zmg2cHhmMG95ZzQza3d1cjNxYjVxdiJ9.-Mx9La2cCP4tEXmDJf139g',
  }, {
      key: 'google.satellite',
      name: 'Satélite - Google Earth',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  }, {
      key: 'google.hybrid',
      name: 'Híbrido - Google Earth',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
  }],
  mapTileMinisterio: [{
      key: 'branco',
      name: 'Branco',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a>',
      url: 'http://localhost/dummy',
    }, {
      key: 'google.satellite',
      name: 'Satélite - Google Earth',
      attr: '&copy; <a href="http://dominussoli.com.br">Dominus Soli</a> | <a href="http://osm.org/copyright">Mapbox</a> contributors',
      url: 'http://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
    }],
    layersControl: {
      'aplicacao': {
        color: '#ff0215',
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.6,
        name: 'Aplicação',
        menuName: 'Aplicação',
        visible: true
      },
      'trajeto': {
        color: '#ffffff',
        weight: 1,
        opacity: 1,
        fillOpacity: 0.6,
        name: 'Trajeto',
        menuName: 'Trajeto',
        visible: true
      },
      'torre': {
        color: '#970a2c',
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Torre e Rede Elétrica',
        menuName: 'Torre e Rede Elétrica',
        visible: true
      },
      'culturaVizinha': {
        color: '#18e552',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Cultura Vizinha',
        menuName: 'Cultura Vizinha',
        visible: true,
        distancia: 250
      },
      'reservaLegal': {
        color: '#c65964',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Reserva Legal e APP',
        menuName: 'Reserva Legal e APP',
        visible: true,
        distancia: 250
      },
      'manancial': {
        color: '#086caa',
        weight: 3,
        opacity: 0.5,
        fillOpacity: 0.7,
        name: 'Manancial de Captação e Manancial',
        menuName: 'Mananciais',
        visible: true,
        distancia: 500
      },
      'povoCidadeVilaBairroMoradia': {
        color: '#ea8221',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Povoação, Cidade, Vila, Bairro e Moradia Isolada',
        menuName: 'Povoações [...] Moradias',
        visible: true,
        distancia: 500
      },
      'sericicultura': {
        color: '#eac81b',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Sericicultura',
        menuName: 'Sericicultura',
        visible: true,
        distancia: 250
      },
      'agrupamento': {
        color: '#ea6629',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Agrupamento de Animais',
        menuName: 'Agrupamentos de Animais',
        visible: true,
        distancia: 250
      },
    },
    layersControlMinisterio: {
      'aplicacao': {
        color: '#ff0215',
        weight: 1,
        opacity: 0.8,
        fillOpacity: 0.6,
        name: 'Aplicação',
        menuName: 'Aplicação',
        visible: true
      },
      'trajeto': {
        color: '#ffffff',
        weight: 1,
        opacity: 1,
        fillOpacity: 0.6,
        name: 'Trajeto',
        menuName: 'Trajeto',
        visible: true
      },
      'sericicultura': {
        color: '#eac81b',
        weight: 1.7,
        opacity: 0.8,
        fillOpacity: 0.5,
        name: 'Sericicultura',
        menuName: 'Sericicultura',
        visible: true,
        distancia: 250
      },
    },
    layersTempGuide: {
      'Área não Aplicada': 'area-nao-aplicada',
      'Aplicação Externa': 'aplicacao-externa',
      'Aplicação': 'aplicacao',
      'Área': 'area'
    },
    observacoesModalOpen: false,
    observacoesModels: [{
      name: 'Presença de obstáculos informados pelo piloto.',
      key: 'OBSTACULOS_PRESENTES',
      checked: false
    }, {
      name: 'Aplicação dentro do padrão desejado.',
      key: 'DENTRO_DO_PADRAO',
      checked: false
    }, {
      name: 'Índice de efetividade abaixo do desejado.',
      key: 'BAIXA_EFETIVIDADE',
      checked: false
    }, {
      name: 'Área não aplicada concentrada.',
      key: 'NAO_APLICADA_CONCENTRADA',
      checked: false
    }, {
      name: 'Presença de sobreposição proposital.',
      key: 'SOBREPOSICAO_PROPOSITAL',
      checked: false
    }, {
      name: 'Presença de sobreposição errática.',
      key: 'SOBREPOSICAO_ERRATICA',
      checked: false
    }, {
      name: 'Presença de sobreposição proposital / errática.',
      key: 'SOB_PROPOSITAL_ERRATICA',
      checked: false
    }, {
      name: 'Alto índice de aplicação externa.',
      key: 'EXTERNO_ALTO',
      checked: false
    }, {
      name: 'Verificar.',
      key: 'VERIFICAR',
      checked: false
    }, {
      name: 'Verificar o fornecimento de insumos x área aplicada.',
      key: 'VERIFICAR_INSUMOS',
      checked: false
    },],
    observacoesText: '',
    legendaAberta: true,
    showing: '',
    mouse: {
      lat: '',
      lng: ''
    }
  };

  leafletMap = null;
  leafletGeoJSON = null;
  leafletLayersGroup = null;
  areasFeatureGroup = L.featureGroup();
  aplicacaoLayer = {
    uuid: "",
    layer: {}
  }
  layerGeoJSON = null;
  laudoHasLayer;

  componentDidMount() {
    this.props.onLoadProtectedAreas();
    const leafletMap = this.leafletMap;//.leafletElement;
    if (this.props.nivel === 'ministerio') {
      leafletMap.on('mousemove', (event) => {
        this.setState({
          mouse: event.latlng
        })
      });
      leafletMap.on('contextmenu', (event) => {
        if (!event.originalEvent.shiftKey && !event.originalEvent.ctrlKey) {
          return
        }
        console.log(event)
        let el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = event.latlng.lat + ', ' + event.latlng.lng;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        toastr.info('COORDENADAS', 'Coordenadas do mouse copiadas para a área de transferência. Pressione CTRL + V para colar.')
      });
    }
  }

  modalToggleHandler = () => {
    this.setState({modalOpen: !this.state.modalOpen})
  };
  legendaToggleHandler = () => {
    this.setState({legendaAberta: !this.state.legendaAberta})
  }

  // setLeafletMapRef = map => {
  //   if (map) {
  //     this.leafletMap = map;
  //   }

  //   console.log('setLeafletMapRef')
  //   console.log(map)
  // };
  setLeafletMapRef = (map) => {
    this.leafletMap = map && map.leafletElement;
  };

  setGeoJSONRef = json => {
    if (!json) {
      return false;
    }
    // if (!this.props.ambiental[this.props.ambiental.selected]) {
    //   this.leafletMap.leafletElement.fitBounds(json.leafletElement.getBounds());
    // }
    if (!this.leafletGeoJSON) {
      this.leafletGeoJSON = json.leafletElement;
    }
    this.leafletMap.fitBounds(this.leafletGeoJSON.getBounds())
  };
  setLayerJSONRef = json => {
    if (!json) {
      return false;
    }
    // this.leafletMap.leafletElement.fitBounds(json.leafletElement.getBounds());
    //this.leafletMap.fitBounds(this.areasFeatureGroup.getBounds());

    if (!!this.leafletMap.leafletElement) {
      this.leafletMap.leafletElement.fitBounds(json.leafletElement.getBounds());
    } else {
      this.leafletMap.fitBounds(json.leafletElement.getBounds().pad(0.5))
    }

  };

  onOverlayAddHandler = (e) => {
    if (Object.keys(this.state.layersTempGuide).includes(e.name)) {
      const layersControl = {...this.state.layersControl};
      layersControl[this.state.layersTempGuide[e.name]].visible = true;
      this.setState({layersControl: layersControl})
    }
  };

  onOverlayRemoveHandler = (e) => {
    if (Object.keys(this.state.layersTempGuide).includes(e.name)) {
      const layersControl = {...this.state.layersControl};
      layersControl[this.state.layersTempGuide[e.name]].visible = false;
      this.setState({layersControl: layersControl})
    }
  };

  onObservacoesModalToggle = () => {
    this.setState({
      observacoesModalOpen: !this.state.observacoesModalOpen
    })
  };

  setLayersGroupRef = json => {
    this.leafletLayersGroup = json && json.leafletElement;
  };

  onCheckboxChangeHangled = index => {
    const value = [...this.state.observacoesModels];
    value[index].checked = !value[index].checked;

    this.setState({
      ...this.state,
      observacoesModels: value
    })
  };

  getFloatingLegendItems = () => {
    if (this.props.nivel) {
      return this.props.nivel === 'ministerio' ? this.state.layersControlMinisterio : this.state.layersControl
    }else {
      return this.state.layersControlMinisterio
    }
  }
  getMapTilesLayers = () => {
    if (this.props.nivel) {
      return this.props.nivel === 'ministerio' ? this.state.mapTileMinisterio : this.state.mapTileLayers
    }else {
      return this.state.mapTileMinisterio
    }
  }

  onObservacoesUpdateHandler = () => {
    let obs = [];
    Object.keys(this.state.observacoesModels).forEach(key => {
      if (this.state.observacoesModels[key].checked) {
        obs.push(this.state.observacoesModels[key].key)
      }
    });

    this.props.onUpdateReportNotes(this.props.uuid,
      obs,
      this.state.observacoesText);
  };

  render() {
    const finalArray = [];
    const zoomControlStyle = {
      marginLeft: '300px'
    };
    const measureOptions = {
      position: 'topright',
      primaryLengthUnit: 'meters',
      secondaryLengthUnit: 'kilometers',
      primaryAreaUnit: 'hectares',
      secondaryAreaUnit: 'acres',
      activeColor: '#17c671',
      completedColor: '#13ad62',
    };
    const layersData = [];
    let modalInformacoes = null;
    if (this.props.areas) {
      const dicionarioClassificacoes = {
        povoacao: 'povoCidadeVilaBairroMoradia',
        cidade: 'povoCidadeVilaBairroMoradia',
        vila: 'povoCidadeVilaBairroMoradia',
        bairro: 'povoCidadeVilaBairroMoradia',
        manancialCaptacao: 'manancial',
        manancial: 'manancial',
        moradiaIsolada: 'povoCidadeVilaBairroMoradia',
        agrupamentoAnimais: 'agrupamento',
        apiario: 'agrupamento',
        urucum: 'culturaVizinha',
        bichoSeda: 'sericicultura',
        culturaVizinha: 'culturaVizinha',
        app: 'reservaLegal',
        reservaLegal: 'reservaLegal',
        redeEletrica: 'torre',
        torre: 'torre'
      }

      const groupedContent = this.props.areas.reduce((acc, cur) => {
        acc[dicionarioClassificacoes[cur.properties.classificacao]].push(cur);
        return acc;
      }, {
        povoCidadeVilaBairroMoradia: [],
        manancial: [],
        sericicultura: [],
        agrupamento: [],
        culturaVizinha: [],
        reservaLegal: [],
        torre: []
      });

      const contentKeys = Object.keys(groupedContent);
      const contentValues = Object.values(groupedContent);

      contentValues.forEach((el, index) => {
        if (el.length > 0) {
          finalArray.push({key: contentKeys[index], values: el});
        }
      });
    }
    console.log('render')

      if (this.props.ambiental[this.props.ambiental.selected]){
        console.log('COLLECTIONS:', this.props.ambiental[this.props.ambiental.selected])
        const dataCollections = this.props.ambiental[this.props.ambiental.selected].collections;
        layersData.push(<LayersControl.Overlay
          name={'Trajeto'}
          checked={true}
          key={this.props.ambiental.selected + "trajeto"}>
          <GeoJSON data={dataCollections['trajeto']}
                   style={this.state.layersControl.trajeto}>

          </GeoJSON>
        </LayersControl.Overlay>);
        layersData.push(<LayersControl.Overlay
          name={'Aplicação'}
          checked={true}
          key={this.props.ambiental.selected + "aplicacao"}>
          <GeoJSON data={dataCollections['raw-aplicacao']}
                   ref={dataCollections['raw-aplicacao'] ? this.setLayerJSONRef : null}
                   // ref={this.setLayerJSONRef}
                   style={this.state.layersControl.aplicacao}>
            <Popup className="text-center">
              {this.props.ambiental[this.props.ambiental.selected].os} {this.props.ambiental[this.props.ambiental.selected].propriedade}
              <br/>
              <div style={{width: '100%', textAlign: 'center'}}>
                {this.props.ambiental[this.props.ambiental.selected].hidden ? "(Aprovação Pendente)" : null}
                <br/>
                <Button onClick={this.onObservacoesModalToggle} theme="success">Detalhes</Button>
              </div>

            </Popup>

          </GeoJSON>
        </LayersControl.Overlay>);

        const informacoes = {...this.props.ambiental[this.props.ambiental.selected]};
        console.log("informacoes");
        console.log(informacoes);
        const reduceAnexos = informacoes.anexos.length > 0 ? informacoes.anexos.reduce((acc, cur) => {
          acc[cur.dadosAmbientaisHasAnexo.chave] = cur;
          return acc;
        }, {}) : [];
        console.log("REDUCE", reduceAnexos)
        modalInformacoes = <Modal open={this.state.observacoesModalOpen} toggle={this.onObservacoesModalToggle} size="lg">
          <ModalHeader>{informacoes.os} - {informacoes.propriedade}</ModalHeader>
          <ModalBody>
            <h5>Informações:</h5>
            <Row>
            <Col>
            <ul style={{fontSize: '1.0rem'}}>
              <li><strong>Codigo:</strong> {informacoes.codigo}</li>
              <li><strong>Data:</strong> {new Date(informacoes.data).toLocaleDateString('pt-BR')}</li>
              <li><strong>Temperatura:</strong> {parseFloat(informacoes.temperatura).toFixed(1)} ºC</li>
              <li><strong>Umidade Relativa:</strong> {parseFloat(informacoes.umidadeRelativa).toFixed(1)}%</li>
            </ul>
            </Col>
            <Col>
            <ul style={{fontSize: '1.0rem'}}>
              <li><strong>Velocidade do Vento:</strong> {parseFloat(informacoes.velocidadeVento).toFixed(1)} km/h</li>
              <li><strong>DPV:</strong> {informacoes.dpv.toUpperCase()}</li>
              <li><strong>Área Aplicação:</strong> {informacoes.areaAplicada.toFixed(2)} ha</li>
              </ul>
            </Col>
            </Row>
            <h5>Arquivos:</h5>
            <Row>
              <Col md="3">
                <Card style={{maxWidth: "300px"}}>
                  <CardHeader/>
                  <CardBody className="text-center">
                    <CardTitle className="text-center"><h6 style={{fontSize: '0.7rem'}}>ORDEM DE SERVIÇO</h6></CardTitle>
                    <Button theme="success" onClick={() => this.props.onGetFileFromUUID(reduceAnexos['ORDEM_SERVICO'] ? reduceAnexos['ORDEM_SERVICO'].dadosAmbientaisHasAnexo.uuid : "null")}>Exibir &rarr;</Button>
                  </CardBody>
                  <CardFooter>PDF</CardFooter>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{maxWidth: "300px"}}>
                  <CardHeader/>
                  <CardBody className="text-center">
                    <CardTitle className="text-center"><h6 style={{fontSize: '0.7rem'}}>RELATÓRIO OPERACIONAL</h6></CardTitle>
                    <Button theme="success" onClick={() => this.props.onGetFileFromUUID(reduceAnexos['RELATÓRIO_APLICACAO'] ? reduceAnexos['RELATÓRIO_APLICACAO'].dadosAmbientaisHasAnexo.uuid : "null")}>Exibir &rarr;</Button>
                  </CardBody>
                  <CardFooter>PDF</CardFooter>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{maxWidth: "300px"}}>
                  <CardHeader/>
                  <CardBody className="text-center">
                    <CardTitle className="text-center"><h6 style={{fontSize: '0.7rem'}}>RECEITUÁRIO AGRONÔMICO</h6></CardTitle>
                    <Button theme="success" onClick={() => this.props.onGetFileFromUUID(reduceAnexos['RECEITUARIO_AGRONOMICO'] ? reduceAnexos['RECEITUARIO_AGRONOMICO'].dadosAmbientaisHasAnexo.uuid : "null")}>Exibir &rarr;</Button>
                  </CardBody>
                  <CardFooter>PDF</CardFooter>
                </Card>
              </Col>
              <Col md="3">
                <Card style={{maxWidth: "300px"}}>
                  <CardHeader/>
                  <CardBody className="text-center">
                    <CardTitle className="text-center"><h6 style={{fontSize: '0.7rem'}}>COLETA DE PAPEL SENSÍVEL</h6></CardTitle>
                    <Button theme="success"onClick={() => this.props.onGetFileFromUUID(reduceAnexos['CHECKLIST'] ? reduceAnexos['CHECKLIST'].dadosAmbientaisHasAnexo.uuid : "null")}>Exibir &rarr;</Button>
                  </CardBody>
                  <CardFooter>PDF</CardFooter>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button className="mr-auto btn-light"
                    onClick={this.onObservacoesModalToggle}>Fechar</Button>
            {/*<Button className="mr-auto btn-success"
                    onClick={() => this.props.onDenyOrApprove(informacoes.uuid, informacoes.hidden ? "approve" : "deny")}>{informacoes.hidden ? "Aprovar" : "Remover Aprovação"}</Button>*/}
          </ModalFooter>
        </Modal>
      }

    return (
      <div>
        <Map zoom={this.state.zoom} center={[this.state.lat, this.state.lng]} className={classes.Map}
                 ref={this.setLeafletMapRef}
                 boundsOptions={{padding: [0, 0]}} zoomControl={false}
                 onOverlayAdd={this.onOverlayAddHandler} onOverlayRemove={this.onOverlayRemoveHandler}>

        {/*<FloatingList content={['OS 3967', 'OS 4144', 'OS 7758', 'OS 9482', 'OS 2553', 'OS 5573']}/>*/}

        {modalInformacoes}

        {/*<Control position={"topright"}>
          <a className={classes.ModalToogleButton} onClick={this.onObservacoesModalToggle}><i
            className="material-icons">search</i></a>
        </Control>*/}
        <LayersControl position="topright" ref={this.setLayersGroupRef}>
          {this.getMapTilesLayers().map((value, index) => (
            <LayersControl.BaseLayer key={value.key} name={value.name} checked={value.key === 'mapbox.light' || value.key === 'branco'}>
              <TileLayer
                attribution={value.attr}
                url={value.url}
              />
            </LayersControl.BaseLayer>))}

            {layersData.length === 2 ? layersData : null}

          {finalArray.length > 0 ? finalArray.map((el, index) => (
            <LayersControl.Overlay name={this.getFloatingLegendItems()[el.key] ? this.getFloatingLegendItems()[el.key].menuName : "Camada"} key={index} checked>
              <FeatureGroup ref={this.setGeoJSONRef}>
                {el.values.map((layer, index) => (
                  <GeoJSON key={index} data={layer} style={this.getFloatingLegendItems()[el.key]} filter={function(feat) { return feat.geometry.type === 'Polygon' ? true : false}}>
                    <Popup className="text-center">
                      {layer.properties.nome} <br/>
                      {layer.properties.identificacao !== 'VAZIO' ? layer.properties.identificacao + <br/> : null}
                      {layer.properties.municipio + " - " + layer.properties.estado}
                    </Popup>
                  </GeoJSON>
                ))}
              </FeatureGroup>
            </LayersControl.Overlay>
          )) : null}
        </LayersControl>

        <ZoomControl position={"topright"}/>


        <Control position={"bottomright"}>
          <Col md='4'>
            <FloatingLegend items={this.getFloatingLegendItems()} toggler={this.legendaToggleHandler} open={this.state.legendaAberta} width/>
          </Col>
        </Control>

        <Control position={"topleft"}>
          <AmbientalMapFilter
            resultOk={false}
            loading={this.props.loading}
          />
        </Control>

        <Control position={"bottomleft"}>
          <div id="leaflet-coords" className="leaflet-control-attribution leaflet-control" style={{bottom: '-10px', left: '-10px'}}>{this.state.mouse.lat}, {this.state.mouse.lng}</div>
        </Control>

        { this.props.nivel !== 'ministerio' && this.props.nivel ? <MeasureControl {...measureOptions} ref={ref => null}/> : null}

      </Map>
    </div>

    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // onLoadReport: (uuid) => dispatch(actions.getReportFromUUID(uuid)),
    onLoadProtectedAreas: () => dispatch(actions.fetchAllProtectedAreas()),
    onDenyOrApprove: (uuid, state) => dispatch(actions.denyOrApproveAmbientalData(uuid, state)),
    onGetFileFromUUID: (uuid) => dispatch(actions.getFileFromUUID(uuid))
    // onUpdateReportNotes: (uuid, obsArray, obsText) => dispatch(actions.updateReportNotes(uuid, obsArray, obsText))
  }
};

const mapStateToProps = state => {
  return {
    report: state.report,
    areas: state.ambiental.collections,
    ambiental: state.ambiental,
    nivel: state.auth.userInfo.nivel,
    loading: state.progressBar.percentage === 0,
  }
};

export default connect(mapStateToProps,
  mapDispatchToProps)(
  AmbientalMap);
